var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("custom-data-table", {
        ref: "datatableschedulDdevolution",
        staticClass: "datatable-schedule-devolution",
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.toScheduleQuery,
          "query-node": "schedules",
          "start-page": true,
          sort: [["scheduled_at", "ASC"]],
          "fixed-filters": _vm.fixedFilters,
        },
        on: {
          "vuetable:row-clicked": _vm.rowClicked,
          "filter-change": function ($event) {
            return _vm.onChangeFilter($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "scheduled_at",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("format_date")(
                          props.rowData.scheduled_at,
                          "DD/MM/YYYY - HH:mm"
                        )
                      ) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "car.license_plate",
              fn: function (props) {
                return props.rowData.car
                  ? _c("span", {}, [
                      _vm._v(
                        " " + _vm._s(props.rowData.car.license_plate) + " "
                      ),
                    ])
                  : _vm._e()
              },
            },
            {
              key: "driver.name",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(" " + _vm._s(props.rowData.driver.name) + " "),
                ])
              },
            },
            {
              key: "metadata.conductor",
              fn: function (props) {
                return props.rowData.metadata
                  ? _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("format_schedule")(
                              props.rowData.metadata.conductor,
                              "conductor"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e()
              },
            },
            {
              key: "driver.cpf",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("format_document")(props.rowData.driver.cpf)
                      ) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "type",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("format_schedule")(props.rowData.type, "type")
                      ) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "status",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("format_schedule")(
                          props.rowData.status,
                          "status"
                        )
                      ) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "booking.status",
              fn: function (props) {
                return props.rowData.booking
                  ? _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("format_schedule")(
                              props.rowData.booking.status,
                              "bookingStatus"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e()
              },
            },
            {
              key: "place.name",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(" " + _vm._s(props.rowData.place.name) + " "),
                ])
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("modal-driver-signup-delivery", {
        attrs: { driver: _vm.current_driver, page: "devolution" },
        on: { finished: _vm.$_modalCallback },
      }),
      _c("modal-driver-signup-rescheduling", {
        attrs: { driver: _vm.current_driver_rescheduling, page: "devolution" },
        on: { finished: _vm.$_modalCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }