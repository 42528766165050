<template>
  <div>
    <custom-data-table
      class="datatable-schedule-devolution"
      ref="datatableschedulDdevolution"
      :fields="fields"
      :filters="filters"
      :query="toScheduleQuery"
      query-node="schedules"
      :start-page="true"
      :sort="[['scheduled_at', 'ASC']]"
      :fixed-filters="fixedFilters"
      @vuetable:row-clicked="rowClicked"
      @filter-change="onChangeFilter($event)"
    >
        <span slot="scheduled_at" slot-scope="props">
          {{ props.rowData.scheduled_at | format_date('DD/MM/YYYY - HH:mm') }}
        </span>

        <span v-if="props.rowData.car" slot="car.license_plate" slot-scope="props">
          {{ props.rowData.car.license_plate }}
        </span>

        <span slot="driver.name" slot-scope="props">
          {{ props.rowData.driver.name }}
        </span>

        <span v-if="props.rowData.metadata" slot="metadata.conductor" slot-scope="props">
          {{ props.rowData.metadata.conductor | format_schedule('conductor') }}
        </span>

        <span slot="driver.cpf" slot-scope="props">
          {{ props.rowData.driver.cpf | format_document }}
        </span>

        <span slot="type" slot-scope="props">
          {{ props.rowData.type | format_schedule('type') }}
        </span>

        <span slot="status" slot-scope="props">
          {{ props.rowData.status | format_schedule('status') }}
        </span>

        <span v-if="props.rowData.booking" slot="booking.status" slot-scope="props">
          {{ props.rowData.booking.status | format_schedule('bookingStatus') }}
        </span>

        <span slot="place.name" slot-scope="props">
          {{ props.rowData.place.name }}
        </span>
    </custom-data-table>
    <modal-driver-signup-delivery :driver="current_driver" page="devolution" @finished="$_modalCallback" />
    <modal-driver-signup-rescheduling :driver="current_driver_rescheduling" page="devolution" @finished="$_modalCallback" />
  </div>
</template>

<script>
import ModalDriverSignupDelivery from '@components/drivers/signup/modals/delivery';
import ModalDriverSignupRescheduling from '@components/drivers/signup/modals/Rescheduling';
import CustomDataTable from '@components/CustomDataTable';
import TO_SCHEDULE_LIST from '@graphql/schedules/queries/schedules-devolution.gql';
import LISTBYFILTERS from '@graphql/place/queries/list_by_filter.gql';
import { FIELDS_DEVOLUTION, STATUS_OPTIONS } from '@src/enums/schedule';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ScheduledDevolutionList',
  components: {
    CustomDataTable,
    ModalDriverSignupDelivery,
    ModalDriverSignupRescheduling
  },
  data() {
    return {
      availablePlaces: [],
      schedule: {},
      current_driver: {},
      current_driver_rescheduling: {},
      toScheduleQuery: TO_SCHEDULE_LIST,
      fixedFilters: {
        ...this.$route.meta.filters,
        ...{ kind: this.$route.meta.kind },
      },
      fields: FIELDS_DEVOLUTION
    };
  },
  apollo: {
		places: {
			query: LISTBYFILTERS,
			variables() {
				return {
					filters: {
						where: {
							status: "ACTIVE",
              type: { devolution: true }
						}
					}
				};
			},
			result(data) {
				if (data.data && !this.availablePlaces.length) {
					const result = data.data.places.items;
          const _self = this;
          this.availablePlaces.push({ value: null, text: _self.$i18n.t('maintenance.labels.all')})
					result.map(function(item, index){
            _self.availablePlaces.push({
              value: item.id,
              text: `${item.name} (${item.address_street_name},\u00A0${item.address_street_number},\u00A0${item.address_neighborhood},\u00A0${item.address_city},\u00A0${item.address_state})`,
            })
					})
				}
			}
		}
	},
  computed: {
    ...mapGetters({
      devolutionSchedulePlace: 'user/devolutionSchedulePlace'
    }),
    now() {
      return this.$moment().format('YYYY/MM/DD HH:mm:ss')
    },
    selectedPlace() {
      const storePlace = this.devolutionSchedulePlace

      if (storePlace.placeId) {
        let end = this.$moment(storePlace.datetime, 'YYYY/MM/DD HH:mm:ss');
        let hours = this.$moment.duration(this.$moment(this.now, 'YYYY/MM/DD HH:mm:ss').diff(end)).hours();

        if (hours >= 5) {
          const place = { placeId: null, datetime: null }

          this.changeDevolutionSchedulePlace(place);

          return null
        }

        return storePlace.placeId
      }

      return null
    },
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: this.$t('maintenance.labels.searchPlaceholder'),
            placeholder: this.$t('maintenance.labels.searchScheduledDeliveryPlaceholder'),
          },
          valueParserType: String,
          graphqlQuery(value) {
            return {
              $or: [
                {
                  driver_name: {
                    $like: `%${value}%`,
                  }
                },
                {
                  driver_cpf: {
                    $like: `%${value}%`,
                  }
                },
                {
                  car_plate: {
                    $like: `%${value}%`,
                  }
                }
              ],
            };
          }
        },
        status: {
          type: 'select',
          cols: 3,
          value: 'SCHEDULED',
          props: {
            label: this.$t('maintenance.fields.scheduleFilter'),
            options: STATUS_OPTIONS
          },
          graphqlQuery(value) {
            return value ? { status: value } : {};
          }
        },
        place: {
          type: 'select',
          cols: 3,
          value: this.selectedPlace,
          props: {
            label: this.$i18n.t('maintenance.labels.filterByPlace'),
            options: this.availablePlaces,
          },
          graphqlQuery(value) {
            return value ? { place: value } : {};
          }
        }
      };
    },
  },

  methods: {
    ...mapActions({
      setDeliverySchedule: 'schedule/setDeliverySchedule',
      changeDevolutionSchedulePlace: 'user/changeDevolutionSchedulePlace',
    }),

    rowClicked({ data }) {
      this.schedule = data;
      this.current_driver = {};
      this.current_driver_rescheduling = {};

      let newData = data
      newData.schedule = data.id
      this.current_driver = newData;

      let tempDriver = data.driver
      tempDriver.schedule = data.id
      this.current_driver_rescheduling = tempDriver;

      this.setDeliverySchedule(newData)

      this.$root.$emit('bv::show::modal', 'driverSignupDelivery');
    },

    async $_modalCallback() {
      this.$root.$emit('bv::hide::modal', 'driverSignupDelivery');
      this.$root.$emit('bv::hide::modal', 'driverSignupRescheduling');
      await this.$refs.datatableschedulDdevolution.$_refresh();
    },

    onChangeFilter(event) {
      if (event) {
        if (event.filterName === 'status') {
          if (!event.event.status) {
            delete this.fixedFilters.status
          } else {
            this.fixedFilters.status = event.event.status
          }
        }

        if (event.filterName === 'place') {
          if (!event.event) {
            delete this.fixedFilters.place;

            this.changeDevolutionSchedulePlace({ placeId: null, datetime: null });
          } else {
            this.changeDevolutionSchedulePlace({
              placeId: event.event.place,
              datetime: this.now
            });

            this.fixedFilters.place = event.event.place
          }
        }
      }
    }
  },
  created() {
    if (this.selectedPlace) this.fixedFilters.place = this.selectedPlace
  }
};
</script>

<style lang="scss">
.datatable-schedule-devolution {
  .custom-data-table__search-btn {
    margin-top: 25px;
  }
}
</style>
